input {
  height: 42px;
  padding: 0 12px;
  font-size: 20px;
  border: solid 1px #999;
}

button {
  height: 42px;
  font-size:20px;
  border: solid 1px #999;
}

.search-box {
  .search-section:not(:first-child) {
    margin-top: 10px;
  }
  .main-search-wrap {
    display:flex;
    flex-direction: column;
    text-align: left;
  }
  .keyword-control-wrap {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    & > button {
      width: 5em;
      border-radius:  0 4px 4px 0;
    }
    & > input {
      border-radius:  4px 0 0 4px;
    }
  }
  .main-search {
    margin-top:2px;
  }
  .input-wrap {
    margin-top:2px;
  }
  label {
    flex: 3;
    width: 100%;
    input {
      width: 100%;
      border-radius: 4px;
    }
    margin-bottom:20px;
  }
}
.advanced-search {
    display:flex;
    flex-direction: column;
    text-align: left;
}
.date-search {
    display:flex;
    flex-direction: row;
    text-align: left;
}
.advanced-search-button {
  background:none;
  color:white;
  border:none;
  outline:none;
  box-shadow: none;
  cursor: pointer;
  font-size:16px;
}
.react-date-picker__inputGroup__divider {
	color:black;
}
.react-date-picker {
	background-color:white;
  border-radius: 4px;
  font-size: 18px;
  .react-date-picker__wrapper {
    padding-left: 0.5em;
    border-radius: 4px;
  }
}
.react-calendar {
  color: black;
  border-radius: 4px;
  padding: 10px;
  font-family: inherit;
  button {
    font-size: 16px;
  }
}
.react-calendar__tile {
 padding: 0; 
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
  padding: 0;
  height: 3.8em;
}

.date-wrap:first-child {
  margin-right: 20px;
}