.search-wrap {
  max-width: 600px;
  margin: 0 auto;
}

.search-form {
  width: 100%;
}

.search-result-list > li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.search-result-list > li > .details {
  padding-left: 20px;
  text-align: left;
}

.results {
  margin: 0 auto;
  margin-top: 20px;
  max-width: 720px;
  &.trend {
    max-width: 1800px;
  }
}

.thumbnail {
  flex-basis: 20%;
  flex-grow: 0;
  flex-shrink: 0;
  padding-top: 0.4em;
}

.thumbnail img {
  min-width: 80px;
}

.details-wrap {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.details-label {
  flex-basis: 6em;
  flex-grow: 0;
  flex-shrink: 0;
}

.details {
  width: 100%;
}

.loading {
  margin-bottom: 20px;
}

.results-over {
  margin-bottom: 20px;
}

.pagination {
  ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
  }
  li {
    display: inline-block;
    a {
      cursor: pointer;
      padding-left: 10px;
      padding-right: 10px;
    }
    &.disabled {
      cursor: default;
      a {
        color: #777;
      }
    }
    &.active a {
      color: rgb(219, 232, 255);
      font-weight: bold;
    }
  }
}

.result-tabs {
  list-style-type: none;
  max-width: 720px;
  margin: 0 auto 10px auto;
  padding: 0;
  li {
    float: left;
    margin-right: 10px;
    button {
      background: none;
      border: none;
      color: #888;
      cursor: pointer;
      font-weight: normal;
      font-size: 120%;
      border-bottom: solid 2px transparent;
    }
    &.active {
      button {
        color: white;
        border-bottom: solid 2px white;
      }
    }
  }
}
